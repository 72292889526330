import { FC, memo, useState } from 'react';
import { useMutation } from 'react-query';
import MaskedInput from 'react-text-mask';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import { Button } from 'components/Button';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import emailMask from 'text-mask-addons/dist/emailMask';
import * as Yup from 'yup';

import { api } from '@/api';
import { Route } from '@/constants/routes';
import { PasswordField } from '@/features/PasswordField';
import { getErrorMessage } from '@/utils/errors';
import { useLogin } from '@/utils/useLogin';

type Props = {
  isBooking?: boolean;
  isDefaultLogged: boolean;
};

export const Login: FC<Props> = memo(({ isBooking = false, isDefaultLogged }) => {
  const { login } = useLogin({ isDefaultLogged });

  const { t } = useTranslation();
  const [message, setMessage] = useState<string | null>(null);

  const initialValues: { login: string; password: string } = { login: '', password: '' };

  const validationSchema = Yup.object().shape({
    login: Yup.string().required('errors.noEmail'),
    password: Yup.string()
      .required('errors.noPhone')
      .min(3, t('errors.passwordLength'))
      .max(128, t('errors.passwordLength')),
  });

  const { isLoading, mutate: handleLogin } = useMutation(api.user.loginWithEmail, {
    onSuccess: (data) => {
      login(data, isBooking);
    },
    onMutate: () => {
      setMessage(null);
    },
    onError: (error) => {
      setMessage(t(getErrorMessage(error)));
    },
  });

  const styles =
    'w-full placeholder:text-gray-200 rounded-xl text-lg py-2 px-4 border ' +
    'border-gray-200 bg-white outline-[#257AEA]';

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(formData) => handleLogin(formData)}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={validationSchema}>
      <Form>
        <div className="grid gap-4 md:gap-8 md:w-2/3 mx-auto">
          <div>
            <Field className={styles} name="login">
              {({ field }: { field: { name: string } }) => (
                <MaskedInput className={styles} mask={emailMask} placeholder={t('email')} type="text" {...field} />
              )}
            </Field>
            <ErrorMessage className="text-red-500 text-sm pt-1" component="p" name="login" />
          </div>

          <div>
            <PasswordField className={styles} />
          </div>
          <div>
            {message && <div className="block text-center text-red-500 mb-4">{message}</div>}
            <Button className="mx-auto" isPending={isLoading} title={t('login')} type="submit" />
            <Link
              className="flex justify-center font-medium mt-6 primary-color-text transition hover:opacity-70"
              href={Route.ResetPassword}>
              {t('forgotPassword')}
            </Link>
          </div>
        </div>
      </Form>
    </Formik>
  );
});
