import { FC } from 'react';
import { useBoolean } from 'react-use-boolean';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';
import { ErrorMessage, Field } from 'formik';

type Props = {
  className?: string;
  name?: string;
  placeholder?: string;
  onBlur?: VoidFunction;
};

const openEye = require('assets/icons/eye.svg');
const closedEye = require('assets/icons/eye-closed.svg');

export const PasswordField: FC<Props> = ({ className = '', name = 'password', placeholder, onBlur }) => {
  const { t } = useTranslation();
  const [showPassword, { toggle: togglePasswordShow }] = useBoolean(false);

  const displayPlaceholder = placeholder || t('password');

  return (
    <div className="relative flex-col flex">
      <Field
        className={cn(className, 'pr-10')}
        name={name}
        onBlur={onBlur}
        placeholder={displayPlaceholder}
        required
        type={showPassword ? 'text ' : 'password'}
      />
      <Image
        alt={displayPlaceholder}
        className="absolute cursor-pointer right-3 top-3 transition hover:opacity-70"
        height={24}
        onClick={togglePasswordShow}
        src={showPassword ? closedEye : openEye}
        width={24}
      />
      <ErrorMessage className="text-red-500 text-sm pt-1" component="p" name={name} />
    </div>
  );
};
