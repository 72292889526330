import { FC, ReactNode } from 'react';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';

import { LoginPageStatus } from '@/types';

type Props = {
  children: ReactNode;
  currentPage: LoginPageStatus;
  onClick: (page: LoginPageStatus) => void;
};

export const NavLinks: FC<Props> = ({ children, currentPage, onClick }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="grid grid-cols-2 w-full font-semibold mb-8 md:mb-16 relative">
        <div>
          <button
            className={cn('text-center w-full self-center pt-1 pb-5 text-lg text-gray-800 border-b-2 border-gray-600', {
              'border-b-[#257AEA] border-b-5': currentPage === LoginPageStatus.Register,
              'transition hover:opacity-70': currentPage !== LoginPageStatus.Register,
            })}
            onClick={() => onClick(LoginPageStatus.Register)}
            type="button">
            {t('signUp')}
          </button>
        </div>
        <div className="absolute top-0 left-1/2 w-0.5 h-8 bg-gray-600" />
        <div>
          <button
            className={cn('text-center w-full self-center pt-1 pb-5 text-lg text-gray-800 border-b-2 border-gray-600', {
              '!border-b-[#257AEA] border-b-5': currentPage === LoginPageStatus.Login,
              'transition hover:opacity-70': currentPage !== LoginPageStatus.Login,
            })}
            onClick={() => onClick(LoginPageStatus.Login)}
            type="button">
            {t('login')}
          </button>
        </div>
      </div>
      {children}
    </div>
  );
};
