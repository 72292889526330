import { AxiosError } from 'axios';
import { ErrorCode, ErrorMessage } from 'types';

export const getErrorMessage = (error: unknown) => {
  const errorType = error as AxiosError;

  const errorData = ((errorType.response && errorType.response.data) as { code: string }) || { code: null };
  const errorCode = errorData.code ? (errorData.code as ErrorCode) : null;

  return (errorCode ? `errors.${errorCode}` : errorType && errorType.message) as ErrorMessage;
};
